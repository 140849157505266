<template>
  <div class="main-page deep-forge-trace">
    <custom-header></custom-header>
    <div class="desc">
      <h4>深度伪造溯源</h4>
      <p> 利用深度学习，深度解析图像生成的驱动原理，解耦出生成模型的指纹痕迹，准确追踪伪造图像的来源出处。 </p>
      <van-button type="info" class="primary-btn" @click="onTraceData">溯源数据展示</van-button>
    </div>
    <div class="image-box">
      <div class="img-cover">
        <van-image fit="contain" :src="imgUrl" lazy-load class="main-img">
          <template v-slot:loading>
            <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
          </template>
        </van-image>
      </div>
      <div class="qr-scanner" v-if="imgLoading">
        <div class="box">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="result-panel" :class="resultData ? '' : 'hidden'">
      <h4>溯源结果</h4>
      <div class="result-box-border"></div>
      <div class="result-inner " :class="resultData.source&&resultData.source != 'real' ? 'deep-forget' : ''">
        <div class="result-box" :class="resultData.source&&resultData.source != 'real' ? 'danger' : ''">
          <div class="result-name" v-if="resultData">
            {{ resultData.source != 'real' ? "图像被篡改" : "图像无篡改"}}
          </div>
          <div class="deep-list">
            <div class="list-left" v-if="resultData.source && resultData.source != 'real'">
              <div class="left-info">
                <div class="info-title">篡改类型：<span>{{resultData.sourceRough}}</span>
                </div>
              </div>
              <div class="left-info">
                <div class="info-title">篡改来源：<span>{{resultData.source}}</span>
                </div>
                  <img @click="dialogs" src="@/assets/img/deep-question.png" class="icon-question" />
              </div>
              <div class="left-info logo">
                <span class="logo-img"></span>
                <span class="info-logo">篡改来源的指纹图或者水印</span>
              </div>
            </div>
            <div v-if="resultData.source && resultData.source != 'real'">
              <img :src="resultData ? resultData.resultUrl : imgUrl" class="list-right" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-button type="info" class="choose-btn" :disabled="imgLoading" @click="chooseExample">选择图片</van-button  >
    <custom-footer></custom-footer>

    <choose-example v-if="chooseShow" :dataList="exampleList" @goBack="hideExample" @onDetect="onDetect" type="forgeTrace"  ></choose-example>

    <custom-dialog :show="showDetail" @close="traceClose" title="伪造算法描述">
      <div class="sub-title">{{resultData.source}}</div>
      <div class="desc">{{resultData.describe}}</div>
    </custom-dialog>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ChooseExample from "@/components/ChooseExample.vue";
import CustomDialog from "@/components/CustomDialog.vue";

import { DATA } from "@/remote";

export default {
  name: "deep-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
    "custom-dialog": CustomDialog
  },
  data() {
    return {
      imgUrl: "",
      imgLoading: false,
      rateValue: 0,
      chooseShow: false,
      exampleList: [],
      resultData: "",
      showDetail: false,
      type:0,
    };
  },
  mounted() {
    this.getDemo(7,this.type);
  },

  methods: {
    dialogs() {
      this.showDetail = true;
    },
    traceClose() {
      this.showDetail = false;
    },
    getDemo(fileType,type) {
      DATA.getDemo({ fileType }).then((res) => {
        if (res.code == 200) {
          this.exampleList = res.data || [];
          let fileUrl = (res.data || [])[0];
          this.imgUrl = fileUrl;
          this.traceSourceCheck(fileUrl,type);
        }
      });
    },
    traceSourceCheck(fileUrl,type) {
      this.imgLoading = true;
      this.resultData = "";
      DATA.traceSourceCheck({fileUrl,type }).then((res) => {
        if (res.code == 200) {
          this.imgLoading = false;
          this.resultData = res.data || {};
          this.rateValue = 5 - parseInt((res.data || {}).score / 20);
        }
        this.imgLoading = false;
      });
    },
    // 选择示例
    chooseExample() {
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    onDetect(fileUrl, type) {
      this.chooseShow = false;
      this.imgUrl = fileUrl;
      this.traceSourceCheck(fileUrl, type);
    },

    onTraceData() {
      this.$router.push({
        name: "TraceData",
      });
    },
  },
};
</script>

<style lang="less" scoped>
  @import "../../assets/less/deepForgeTrace";
</style>
